.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 8px 6px;
  width: 254px;
  max-width: 254px;

  .now-ui-icons{
      opacity: 1;
      top: 2px;
  }
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before{
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: $white-color;
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:before{
    display: none;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:after{
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: auto;
    bottom: -6px;
    right: auto;
    left: 10px;
    color: $white-color;
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: -20px;
}
// .bootstrap-datetimepicker-widget.dropdown-menu.top.open {
//   margin-top: auto;
//   margin-bottom: 3px;
// }
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  border-width: 0;
  color: #66615B;
  background-color: transparent;
}
.bootstrap-datetimepicker-widget a[data-action="togglePicker"],
.bootstrap-datetimepicker-widget a[data-action="togglePicker"]:hover {
    color: $primary-color;
}
.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.5em;
  margin: 3px;
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  background-color: $primary-color;
  height: 38px;
  padding: 0;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.bootstrap-datetimepicker-widget .btn{
    margin: 0 !important;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 3px;
  color: $primary-color;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0px !important;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td > div, .bootstrap-datetimepicker-widget table th > div {
  text-align: center;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-weight: 300;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled, .bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #cfcfca;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev span, .bootstrap-datetimepicker-widget table th.next span {
  border-radius: 4px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  color: $primary-color;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  color: $primary-color;
  padding-bottom: 5px;
  padding-top: 10px;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span, .bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover {
  background: #eee;
}
// .bootstrap-datetimepicker-widget table td > div {
//   border-radius: 4px;
//   height: 54px;
//   line-height: 54px;
//   width: 54px;
//   text-align: center;
// }
.bootstrap-datetimepicker-widget table td.cw > div {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #cfcfca;
}
.bootstrap-datetimepicker-widget table td.day > div,
.bootstrap-datetimepicker-widget table td.minute > div,
.bootstrap-datetimepicker-widget table td.hour > div {
  height: 30px;
  line-height: 2.2;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: -1;
  position: relative;
  font-weight: 300;
  font-size: 14px;
  border: none;
  cursor: pointer;
  @include transition($general-transition-time, $transition-ease);
}
.bootstrap-datetimepicker-widget table td.day:hover > div, .bootstrap-datetimepicker-widget table td.hour:hover > div, .bootstrap-datetimepicker-widget table td.minute:hover > div, .bootstrap-datetimepicker-widget table td.second:hover > div {
  background: #eee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old > div, .bootstrap-datetimepicker-widget table td.new > div {
  color: $default-color;
}
.bootstrap-datetimepicker-widget table td.today > div:before {
  content: '';
  display: inline-block;
  border: 0 0 7px 7px solid transparent;
  border-bottom-color: #68B3C8;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.bootstrap-datetimepicker-widget table td.active > div, .bootstrap-datetimepicker-widget table td.active:hover > div {
  background-color: $primary-color;
  color: $white-color;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}
.bootstrap-datetimepicker-widget table td.active.today:before > div {
  border-bottom-color: #FFFFFF;
}
.bootstrap-datetimepicker-widget table td.disabled > div, .bootstrap-datetimepicker-widget table td.disabled:hover > div {
  background: none;
  color: #cfcfca;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: $primary-color;
  color: #FFFFFF;
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #cfcfca;
}
.bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #cfcfca;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}

.input-group.date .input-group-text {
  cursor: pointer;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget{
    .picker-switch,
    table th.prev span,
    table th.next span,
    table td.day > div,
    a[data-action="togglePicker"],
    a[data-action="togglePicker"]:hover,
    span,
    .timepicker-hours span,
    .timepicker-minutes span,
    .separator,
    table td.minute > div,
    table td.hour > div{
        color: $white-color;
    }



    table th.dow{
        color: $opacity-8;
    }

    table td.old > div,
    table td.new > div{
        color: rgba(255,255,255, .4);
    }

    button[data-action]{
        background-color: $white-color;
    }

    table td.active > div,
    table td.active:hover > div{
        background-color: $white-color;
    }

    table td:not(.active).day:hover > div,
    table td.hour:hover > div,
    table td.minute:hover > div,
    table td.second:hover > div,
    table td span:hover{
        background: $opacity-2;
    }

    table thead tr:first-child th:hover span,
    table thead tr:first-child th.picker-switch:hover{
        background-color: $opacity-2;
    }
}


input.datetimepicker[data-color="orange"] + ngb-datepicker{

    background-color: $primary-color;
    .ngb-dp-months .ngb-dp-day .btn-light,
    .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
    .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover{
        color: $white-color !important;
    }
    &:before{
      color: $primary-color;
    }
    .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected="true"]{
        background-color: $white-color !important;
        color: $primary-color !important;
    }
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted{
        color: $white-color!important;
    }
}
input.datetimepicker[data-color="blue"] + ngb-datepicker{
    background-color: $info-color;
    .ngb-dp-months .ngb-dp-day .btn-light,
    .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
    .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover{
        color: $white-color;
    }
    &:before{
      color: $info-color;
    }
    .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected="true"]{
        background-color: $white-color !important;
        color: $info-color !important;
    }
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted{
        color: $white-color!important;
    }
}
input.datetimepicker[data-color="green"] + ngb-datepicker{

    background-color: $success-color;
    .ngb-dp-months .ngb-dp-day .btn-light,
    .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
    .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover{
        color: $white-color;
    }
    &:before{
      color: $success-color;
    }
    .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected="true"]{
        background-color: $white-color !important;
        color: $success-color !important;
    }
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted{
        color: $white-color!important;
    }
}
input.datetimepicker[data-color="red"] + ngb-datepicker{
    background-color: $danger-color;
    .ngb-dp-months .ngb-dp-day .btn-light,
    .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
    .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover{
        color: $white-color;
    }
    &:before{
      color: $danger-color;
    }
    .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected="true"]{
        background-color: $white-color !important;
        color: $danger-color !important;
    }
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted{
        color: $white-color!important;
    }
}
input.datetimepicker[data-color="yellow"] + ngb-datepicker{
    background-color: $warning-color;
    .ngb-dp-months .ngb-dp-day .btn-light,
    .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
    .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover{
        color: $white-color;
    }
    &:before{
      color: $warning-color;
    }
    .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected="true"]{
        background-color: $white-color !important;
        color: $warning-color !important;
    }
    .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted{
        color: $white-color!important;
    }
}

ngb-datepicker{
    border: 0 none !important;
    display: block !important;
    color: $default-color;
    padding: 8px 6px;
    position: absolute !important;
    -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.125rem !important;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear !important;

    &.dropdown-menu{
      top: 100% !important;
      &:before{
          display: inline-block;
          position: absolute;
          width: 0;
          height: 0;
          vertical-align: middle;
          content: "";
          top: -7px;
          left: 10px;
          right: auto;
          border-bottom: .4rem solid;
          border-right: .41rem solid transparent;
          border-left: .4rem solid transparent;
      }
    }
    .bg-light{
      background: transparent !important;
    }

    &:focus{
        outline: none !important;
    }
    .ngb-dp-header{
        background: 0 0!important;
        border-bottom: none !important;
        ngb-datepicker-navigation{
          color: $primary-color;
            .ngb-dp-arrow-btn{
                .ngb-dp-navigation-chevron{
                  color: $primary-color;
                }
            }
            ngb-datepicker-navigation-select{
                .custom-select{
                  font-weight: 300;
                  color: $primary-color;
                    border: none !important;
                    background: transparent!important;
                    -webkit-appearance: menulist !important;
                }
            }

        }
    }

    .ngb-dp-week{

      background: 0 0!important;
      border: none;
      .ngb-dp-weekday{
        color: #fff;
        font-style: normal!important;
        font-size: 14px;
        opacity: .8;
      }
    }
    .ngb-dp-months{
        ngb-datepicker-month-view{
            .ngb-dp-week{
              border: none;
                .ngb-dp-weekday{
                    color: $primary-color;
                    padding-top: 10px;
                    opacity: 0.8;
                    font-style: normal !important;
                    line-height: 20px;
                    // font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
                    font-weight: 300;
                }
                .small{
                  font-size: 14px;
                }
                .ngb-dp-day{
                    padding: 1px;
                    text-align: center;
                    z-index: 1;
                    cursor: pointer;
                    -webkit-font-smoothing: antialiased;
                    // font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
                    font-weight: 300;
                    .btn-light{
                        border-radius: 50% !important;
                        color: $black-color;
                        &:hover,
                        &:active{
                            @include transition(150ms, linear);
                            color: $black-color;
                            background-color: $opacity-gray-3;
                        }
                    }
                    .text-muted{
                      color: $default-color;
                    }
                }
            }
        }
    }
}
